<template>
  <div>
    <layout v-if="!mobile">
      <div class="popup-overlay" v-if="stakeDialogShow">
        <div class="stake_dialog">
          <div class="close-btn" @click="dialogClose()"/>
          <div class="stake_top">
            {{ dialogTitle }} {{ stakeText }}
          </div>
          <div style="height: 30px"/>
          <div class="x-line"/>
          <div style="height: 30px"/>
          <div class="flex justify-between items-center panel">
            <div>
              <div>STAKE</div>
              <div style="height: 20px"/>
              <input class="amount-input" v-model="amount" type="number">
              <div v-if="dialogTitle === 'stake' && poolInfoArr[poolIndex].isSetLimit">
                <div style="height: 20px"/>
                <div v-if="poolInfoArr[poolIndex].isSetPoolQuota">Pool Hard Cap: {{ poolInfoArr[poolIndex].totalPower }}
                  /
                  {{ poolInfoArr[poolIndex].poolQuotaLimit }}
                </div>
                <div v-if="poolInfoArr[poolIndex].isSetUserQuota">Your Hard Cap: {{ poolInfoArr[poolIndex].staked }} /
                  {{ poolInfoArr[poolIndex].userQuotaLimit }}
                </div>
                <div v-if="poolInfoArr[poolIndex].isSetPoolQuota || poolInfoArr[poolIndex].isSetUserQuota">Your current
                  maximum staking amount is {{ maxStake }}
                </div>
              </div>
            </div>
            <div>
              <div>BALANCE: {{ setDecimal(maxAmount, 6, false, false) }}</div>
              <div style="height: 18px"/>
              <div class="max-btn" @click="setMax()">MAX</div>
              <div v-show="dialogTitle === 'unstake'">
                <div style="height: 18px"/>
                <div>
                  {{ isDeadlineReached ? "Unstake Fee: 0" : "Unstake Fee: " + poolInfoArr[poolIndex].feeRatio + "%" }}
                </div>
              </div>
            </div>
          </div>
          <div style="height: 36px"/>
          <div class="x-line"/>
          <div style="height: 30px"/>
          <div class="flex justify-center">
            <div class="confirm" v-if="dialogTitle === 'unstake'" @click="withdraw(contract)">Confirm</div>
            <div class="confirm" v-if="dialogTitle === 'stake'" @click="stake(contract)">Confirm</div>
            <div class="confirm" @click="dialogClose()">Cancel</div>
          </div>
          <div class="tips" v-if="this.poolInfoArr[this.poolIndex].isPledgeRestriction">
            <div v-if="dialogTitle === 'stake'">Kindly Note that you can stake ONCE, and learn about <a
                href="https://trustfi.medium.com/new-tier-system-of-trustfi-for-upcoming-idos-loyalty-governance-a4dc3e3336c7"
                target="_blank">how Loyalty Governance works</a>.
            </div>
            <div v-if="dialogTitle === 'unstake'">Kindly note that unstaking will affect your TFI Points and Rankings in
              Tier System. <a
                  href="https://trustfi.medium.com/new-tier-system-of-trustfi-for-upcoming-idos-loyalty-governance-a4dc3e3336c7"
                  target="_blank">how Loyalty Governance works</a>.
            </div>
          </div>
        </div>
      </div>
      <popup-r-o-i
          v-if="feeDialogShow"
          :fee-data="feeData"
          :stake-text="stakeText"
          :fee-price="feePrice"
          :days-num="daysNum"
          :fee-num="feeNum"
          @close="feeDialogShow = false"
      />
      <template v-slot:banner>
        <div class="absolute banner-bg"/>
      </template>
      <div class="bg-text-1"/>
      <div class="bg-text-2"/>
      <div style="height: 357px"/>
      <div class="page-title">Farms</div>
      <div style="height: 46px"/>
      <p class="page-desc">
        Stake various tokens to earn CHEERS and allocations for IGO.
        Rewards are calculated per block.
      </p>
      <div style="height: 168px"/>
      <div class="filter-row">
        <div class="switch-panel">
          <div class="bg" @click="checked = !checked">
            <div
                class="on-bg"
                :style="{ width: checked ? '40px' : 0 }"
            ></div>
            <div
                class="btn"
                :style="{ left: checked ? '25px' : '-5px' }"
            ></div>
          </div>
          Staked only
        </div>
        <div class="flex">
          <div :class="['filter-btn', { 'is-selected': igoOption === 'live'}]" @click="setIgoOption('live')">
            Live
          </div>
          <div :class="['filter-btn', { 'is-selected': igoOption === 'finished'}]" @click="setIgoOption('finished')">
            Finished
          </div>
        </div>
      </div>
      <div style="height: 78px"/>
      <div class="pool-list">
        <div class="card" v-for="(pool, index) in poolInfoArrForShow" :key="index">
          <div class="coin-logo">
            <div
                class="logo"
                :style="{
            backgroundImage: `url('${pool.earnImg}')`,
          }"
            >
            </div>
            <div class="little-logo">
              <img :class="pool.poolType === 'token' ? '' : 'lp-icon'" :src="pool.lpImg">
            </div>
          </div>
          <div style="height: 120px"/>
          <div class="card-title">
            {{ pool.poolName }}
          </div>
          <div style="height: 11px"/>
          <div class="flex justify-center">
            <div class="tag" v-if="pool.poolSign">{{ pool.poolSign }}</div>
            <div v-else style="height: 25px"/>
          </div>
          <div style="height: 25px"/>
          <div class="flex justify-between text-left">
            <div>
              <div class="panel-label">Stake</div>
              <div class="panel-value">{{ pool.stake }}</div>
            </div>
            <div>
              <div class="panel-label">Earn</div>
              <div class="panel-value">{{ pool.earn }}</div>
            </div>
            <div>
              <div class="panel-label">APR</div>
              <div class="panel-value">
                {{ setDecimal(pool.APR, 3, false, false) }}%
                <img src="../../assets/images/pc/jisuan.png"
                     @click="feeOperDialog(pool.APR, pool.stake, pool.earn, pool.punishTime, pool.feeRatio)">
              </div>
            </div>
          </div>
          <div style="height: 18px"/>
          <div class="x-line"/>
          <div style="height: 17px"/>
          <div class="action-row">
            <div>
              <div class="action-label">{{ pool.earn }} Earned</div>
              <div class="action-value">{{ setDecimal(pool.earned, 6, false, false) }}</div>
            </div>
            <div :class="['act-btn', { 'enabled': pool.isStart }]" @click="harvest(pool.poolAddress, pool.isStart)">
              Harvest
            </div>
          </div>
          <div style="height: 49px"/>
          <div class="action-row">
            <div class="warn-text">
              Unstaking Fee
              <img src="../../../public/images/farms/icon_question.png" width="8"/>
              <div class="detail-content">
                <ul>
                  <li>Unstake within 7 days will be charged 0% fees.</li>
                  <div style="height: 15px"/>
                  <li>Timer resets every time you Unstake and Stake.</li>
                </ul>
              </div>
            </div>
            <div>
              <div class="action-label">{{ pool.stake }} Staked</div>
              <div class="action-value">{{ setDecimal(pool.staked, 6, false, false) }}</div>
            </div>
            <div class="act-btn enabled" v-if="$store.state.accounts.length === 0" @click="showConnectWallet()">
              Connect Wallet
            </div>
            <div class="act-btn enabled" v-else-if="(pool.approve <= 50000*1e18)"
                 @click="coinApproveCheersMortgage(pool.lpToken, pool.poolAddress, pool.isStart)">
              Approve
            </div>
            <div class="flex" v-else>
              <template>
                <div v-if="parseFloat(pool.staked) === 0" class="act-btn" :class="{ 'little-btn': pool.isStake }">
                  Unstake
                </div>
                <div v-else
                     class="act-btn enabled"
                     :class="{ 'little-btn': pool.isStake }"
                     @click="operDialog(index,'unstake', pool.poolAddress, pool.stake, pool.lpToken)">
                  Unstake
                </div>
              </template>
              <div class="act-btn enabled little-btn"
                   v-if="pool.isStake"
                   @click="operDialog(
                                     index,
                                     'stake',
                                     pool.poolAddress,
                                     pool.stake,
                                     pool.lpToken
                                 )"
              > +
              </div>
            </div>
          </div>
          <div style="height: 14px"/>
          <div class="x-line"/>
          <div class="detail-area" v-if="pool.hide">
            <img
                src="/images/farms/arrow_up.png"
                class="up-btn"
                @click="pool.hide = false"
            />
            <div style="height: 12px"/>
            <div class="detail-row">
              <div class="label">TVL</div>
              <div class="value">＄{{ setDecimal(pool.TVL, 6, false, false) }}</div>
            </div>
            <div style="height: 8px"/>
            <div class="detail-row">
              <div class="label">Rewards end in</div>
              <div class="value">
                {{ pool.isStart ? (pool.end > 0 ? pool.endText : 'Has ended') : 'Not started' }}
                <img src="/images/farms/icon_clock.png"/>
              </div>
            </div>
            <div style="height: 14px"/>
            <div
                class="link"
                v-if="pool.viewProjectSite"
                @click="openUrl(pool.viewProjectSite)"
            >
              View Project Site
              <img src="/images/farms/green_arrow.png"/>
            </div>
            <div style="height: 14px"/>
            <div
                class="link"
                v-if="pool.viewContract"
                @click="openUrl('https://bscscan.com/address/' + pool.viewContract)"
            >
              View Contract
              <img src="/images/farms/green_arrow.png"/>
            </div>
            <div style="height: 60px"/>
          </div>
          <template v-else>
            <div style="height: 16px"/>
            <div class="bottom-part" @click="pool.hide = true">
              <div>Details</div>
              <img src="/images/farms/arrow_down.png"/>
            </div>
            <div style="height: 60px"/>
          </template>
        </div>
      </div>
    </layout>
    <mobile-layout v-else ref="mobileLayout">
      <div class="popup-overlay" v-if="stakeDialogShow">
        <div class="m-stake_dialog">
          <div class="close-btn" @click="dialogClose()"/>
          <div class="stake_top">
            {{ dialogTitle }}<br/>{{ stakeText }}
          </div>
          <div style="height: 0.17rem"/>
          <div class="x-line"/>
          <div style="height: 0.12rem"/>
          <div>STAKE</div>
          <div style="height: 0.08rem"/>
          <input class="amount-input" v-model="amount" type="number">
          <div v-if="dialogTitle === 'stake' && poolInfoArr[poolIndex].isSetLimit">
            <div style="height: 0.13rem"/>
            <div v-if="poolInfoArr[poolIndex].isSetPoolQuota">Pool Hard Cap: {{ poolInfoArr[poolIndex].totalPower }} /
              {{ poolInfoArr[poolIndex].poolQuotaLimit }}
            </div>
            <div v-if="poolInfoArr[poolIndex].isSetUserQuota">Your Hard Cap: {{ poolInfoArr[poolIndex].staked }} /
              {{ poolInfoArr[poolIndex].userQuotaLimit }}
            </div>
            <div v-if="poolInfoArr[poolIndex].isSetPoolQuota || poolInfoArr[poolIndex].isSetUserQuota">Your current
              maximum staking amount is {{ maxStake }}
            </div>
          </div>
          <div style="height: 0.26rem"/>
          <div>BALANCE: {{ setDecimal(maxAmount, 6, false, false) }}</div>
          <div style="height: 0.2rem"/>
          <div class="max-btn" @click="setMax()">MAX</div>
          <div v-show="dialogTitle === 'unstake'">
            <div style="height: 0.12rem"/>
            <div>
              {{ isDeadlineReached ? "Unstake Fee: 0" : "Unstake Fee: " + poolInfoArr[poolIndex].feeRatio + "%" }}
            </div>
          </div>
          <div style="height: 0.15rem"/>
          <div class="x-line"/>
          <div style="height: 0.19rem"/>
          <div class="flex justify-center">
            <div class="confirm" v-if="dialogTitle === 'unstake'" @click="withdraw(contract)">Confirm</div>
            <div class="confirm" v-if="dialogTitle === 'stake'" @click="stake(contract)">Confirm</div>
            <div class="confirm" @click="dialogClose()">Cancel</div>
          </div>
          <div class="tips" v-if="this.poolInfoArr[this.poolIndex].isPledgeRestriction">
            <div v-if="dialogTitle === 'stake'">Kindly Note that you can stake ONCE, and learn about <a
                href="https://trustfi.medium.com/new-tier-system-of-trustfi-for-upcoming-idos-loyalty-governance-a4dc3e3336c7"
                target="_blank">how Loyalty Governance works</a>.
            </div>
            <div v-if="dialogTitle === 'unstake'">Kindly note that unstaking will affect your TFI Points and Rankings in
              Tier System. <a
                  href="https://trustfi.medium.com/new-tier-system-of-trustfi-for-upcoming-idos-loyalty-governance-a4dc3e3336c7"
                  target="_blank">how Loyalty Governance works</a>.
            </div>
          </div>
        </div>
      </div>
      <mobile-popup-r-o-i
          v-if="feeDialogShow"
          :fee-data="feeData"
          :stake-text="stakeText"
          :fee-price="feePrice"
          :days-num="daysNum"
          :fee-num="feeNum"
          @close="feeDialogShow = false"
      />
      <div style="height: 0.47rem"/>
      <img src="/images/farms/mobile/bg_top_banner.png" style="height: 3.94rem"/>
      <img class="m-top-banner-text" src="/images/farms/bg_text_1.png"/>
      <img
          class="m-top-banner-text"
          src="/images/farms/bg_text_2.png"
          style="top: 3.85rem; left: 2.93rem"
      />
      <div class="m-page-title">Farms</div>
      <div style="height: 0.06rem"/>
      <p class="m-page-desc">
        Stake various tokens to earn CHEERS and allocations for IGO.
        Rewards are calculated per block.
      </p>
      <div style="height: 0.4rem"/>
      <div class="m-switch-panel">
        <div class="bg" @click="checked = !checked">
          <div
              class="on-bg"
              :style="{ width: checked ? '40px' : 0 }"
          ></div>
          <div
              class="btn"
              :style="{ left: checked ? '25px' : '-5px' }"
          ></div>
        </div>
        Staked only
      </div>
      <div style="height: 0.35rem"/>
      <div class="flex justify-center">
        <div :class="['m-filter-btn', { 'is-selected': igoOption === 'live'}]" @click="setIgoOption('live')">
          Live
        </div>
        <div :class="['m-filter-btn', { 'is-selected': igoOption === 'finished'}]" @click="setIgoOption('finished')">
          Finished
        </div>
      </div>
      <div style="height: 0.59rem"/>
      <div class="pool-list-window" :style="{ height: isAnyCardShowDetail ? '4.6rem' : '3.9rem'}">
        <div
            class="m-pool-list"
            :style="{ transform: `translateX(${poolListOffset}rem)` }"
        >
          <div class="card" v-for="(pool, index) in poolInfoArrForShow" :key="index">
            <div class="coin-logo">
              <div
                  class="logo"
                  :style="{backgroundImage: `url('${pool.earnImg}')`,}"
              >
              </div>
              <div class="little-logo">
                <img :class="pool.poolType === 'token' ? '' : 'lp-icon'" :src="pool.lpImg">
              </div>
            </div>
            <div style="height: 0.8rem"/>
            <div class="card-title">
              {{ pool.poolName }}
            </div>
            <div style="height: 0.05rem"/>
            <div class="flex justify-center">
              <div class="tag" v-if="pool.poolSign">{{ pool.poolSign }}</div>
              <div v-else style="height: 0.17rem"/>
            </div>
            <div style="height: 0.15rem"/>
            <div class="flex justify-between text-left">
              <div>
                <div class="panel-label">Stake</div>
                <div class="panel-value">{{ pool.stake }}</div>
              </div>
              <div>
                <div class="panel-label">Earn</div>
                <div class="panel-value">{{ pool.earn }}</div>
              </div>
              <div>
                <div class="panel-label">APR</div>
                <div class="panel-value">
                  {{ setDecimal(pool.APR, 3, false, false) }}%
                  <img src="../../assets/images/pc/jisuan.png"
                       @click="feeOperDialog(pool.APR, pool.stake, pool.earn, pool.punishTime, pool.feeRatio)">
                </div>
              </div>
            </div>
            <div style="height: 0.11rem"/>
            <div class="x-line"/>
            <div style="height: 0.11rem"/>
            <div class="action-row">
              <div>
                <div class="action-label">{{ pool.earn }} Earned</div>
                <div class="action-value">{{ setDecimal(pool.earned, 6, false, false) }}</div>
              </div>
              <div :class="['act-btn', { 'enabled': pool.isStart }]" @click="harvest(pool.poolAddress, pool.isStart)">
                Harvest
              </div>
            </div>
            <div style="height: 0.18rem"/>
            <div class="action-row">
              <div class="warn-text">
                Unstaking Fee
                <img src="../../../public/images/farms/icon_question.png" width="8"/>
                <div class="detail-content">
                  <ul>
                    <li>Unstake within 7 days will be charged 0% fees.</li>
                    <div style="height: 0.15rem"/>
                    <li>Timer resets every time you Unstake and Stake.</li>
                  </ul>
                </div>
              </div>
              <div>
                <div class="action-label">{{ pool.stake }} Staked</div>
                <div class="action-value">{{ setDecimal(pool.staked, 6, false, false) }}</div>
              </div>
              <div class="act-btn enabled" v-if="$store.state.accounts.length === 0" @click="connectWallet">
                Connect Wallet
              </div>
              <div class="act-btn enabled" v-else-if="(pool.approve <= 50000*1e18)"
                   @click="coinApproveCheersMortgage(pool.lpToken, pool.poolAddress, pool.isStart)">
                Approve
              </div>
              <div class="flex" v-else>
                <template>
                  <div v-if="parseFloat(pool.staked) === 0" class="act-btn" :class="{ 'little-btn': pool.isStake }">
                    Unstake
                  </div>
                  <div v-else
                       class="act-btn enabled"
                       :class="{ 'little-btn': pool.isStake }"
                       @click="operDialog(index,'unstake', pool.poolAddress, pool.stake, pool.lpToken)">
                    Unstake
                  </div>
                </template>
                <div class="act-btn enabled little-btn"
                     v-if="pool.isStake"
                     @click="operDialog(
                                     index,
                                     'stake',
                                     pool.poolAddress,
                                     pool.stake,
                                     pool.lpToken
                                 )"
                > +
                </div>
              </div>
            </div>
            <div style="height: 0.06rem"/>
            <div class="x-line"/>
            <div class="detail-area" v-if="pool.hide">
              <img
                  src="/images/farms/arrow_up.png"
                  class="up-btn"
                  @click="pool.hide = false"
              />
              <div style="height: 0.1rem"/>
              <div class="detail-row">
                <div class="label">TVL</div>
                <div class="value">＄{{ setDecimal(pool.TVL, 6, false, false) }}</div>
              </div>
              <div style="height: 0.08rem"/>
              <div class="detail-row">
                <div class="label">Rewards end in</div>
                <div class="value">
                  {{ pool.isStart ? (pool.end > 0 ? pool.endText : 'Has ended') : 'Not started' }}
                  <img src="/images/farms/icon_clock.png"/>
                </div>
              </div>
              <div style="height: 0.1rem"/>
              <div
                  class="link"
                  v-if="pool.viewProjectSite"
                  @click="openUrl(pool.viewProjectSite)"
              >
                View Project Site
                <img src="/images/farms/green_arrow.png"/>
              </div>
              <div style="height: 0.1rem"/>
              <div
                  class="link"
                  v-if="pool.viewContract"
                  @click="openUrl('https://bscscan.com/address/' + pool.viewContract)"
              >
                View Contract
                <img src="/images/farms/green_arrow.png"/>
              </div>
              <div style="height: 0.4rem"/>
            </div>
            <template v-else>
              <div style="height: 0.1rem"/>
              <div class="bottom-part" @click="pool.hide = true">
                <div>Details</div>
                <img src="/images/farms/arrow_down.png"/>
              </div>
              <div style="height: 0.4rem"/>
            </template>
          </div>
        </div>
      </div>
      <div style="height: 0.4rem"/>
      <div class="scroll-panel">
        <div class="prev-btn" @click="goPrevPool">
          <img src="/images/igo/mobile/icon_arrow_left.png"/>
        </div>
        <div class="next-btn" @click="goNextPool">
          <img src="/images/igo/mobile/icon_arrow_right.png"/>
        </div>
      </div>
      <div style="height: 0.45rem"/>
    </mobile-layout>
  </div>
</template>

<script>
import * as metaMaskTools from "../../tools/chain";
import {mixin1} from "../../tools/mixins";
import toolUtils from "../../tools/toolUtils";
import Layout from "@/components/v2/Layout";
import {farmsProjectList} from "@/consts/farmsProjectList";
import PopupROI from "@/views/farms/PopupROI";
import isMobile from "@/consts/isMobile";
import MobileLayout from "@/components/v2/MobileLayout";
import MobilePopupROI from "@/views/farms/MobilePopupROI";

export default {
  name: "Index",
  components: {MobileLayout, PopupROI, Layout, MobilePopupROI},
  mixins: [mixin1],
  data() {
    return {
      poolListOffset: 0,
      mobile: isMobile(),
      checked: false,
      isDeadlineReached: false,
      poolInfoArr: farmsProjectList,
      igoOption: 'finished',
      stakeText: '',
      lpTokenText: '',
      feePrice: 0,
      accounts: null,
      poolIndex: 0,
      feeDialogShow: false,
      stakeDialogShow: false,
      amount: 0,
      maxAmount: 0,
      dialogTitle: '',
      contract: null,
      feeData: null,
      daysNum: 0,
      feeNum: 0,
      maxStake: 0
    };
  },
  watch: {},
  computed: {
    isAnyCardShowDetail() {
      return !!this.poolInfoArrForShow.find((pool) => pool.hide);
    },
    poolInfoArrForShow() {
      return this.poolInfoArr.filter((pool) => {
        const statusFulfilled = (this.igoOption !== 'live') ? pool.end === 0 : pool.end > 0
        const stakeFulfilled = this.checked ? pool.isMortgage : true
        return statusFulfilled && stakeFulfilled
      })
    }
  },
  created() {
    if (this.mobile) {
      document.documentElement.style.fontSize =
          document.documentElement.clientWidth / 4.14 + "px";
    }
    setTimeout(() => {
      this.onAccountsChanged();
    }, 1000)
    if (localStorage.getItem('accounts')) {
      this.accounts = localStorage.getItem('accounts')
    }
  },
  mounted() {
    this.initMining()
    setTimeout(() => {
      this.initMiningTime()
    }, 1000)
  },
  beforeDestroy() {
  },
  methods: {
    goPrevPool() {
      const maxOffset = -1 * this.poolInfoArrForShow.length * 2.67 + 4.14;
      if (this.poolListOffset + 2.67 >= 0) {
        this.poolListOffset = 0;
      } else if (this.poolListOffset <= maxOffset) {
        this.poolListOffset = this.poolListOffset + 1.93;
      } else {
        this.poolListOffset = this.poolListOffset + 2.67;
      }
    },
    goNextPool() {
      const maxOffset = -1 * this.poolInfoArrForShow.length * 2.67 + 4.14;
      if (this.poolListOffset >= 0) {
        this.poolListOffset = -1.93;
      } else if (this.poolListOffset - 2.67 <= maxOffset) {
        this.poolListOffset = maxOffset;
      } else {
        this.poolListOffset = this.poolListOffset - 2.67;
      }
    },
    openUrl(url) {
      window.open(url)
    },
    onRefresh(accounts) {
      this.$store.commit('setState', {
        accounts
      });
      localStorage.setItem('accounts', accounts);
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts')
      }
      this.initMining()
    },
    isHide(index) {
      this.poolInfoArr[index].hide = !this.poolInfoArr[index].hide
    },
    setIgoOption(text) {
      this.igoOption = text
    },
    connectWallet() {
      this.$refs.mobileLayout.connectWallet()
    },
    showConnectWallet() {
      this.$store.commit('setState', {
        connectWalletShow: true
      })
    },
    setDecimal(numVal, numDecimalMax, roundUp, science) {
      return toolUtils.setDecimal(numVal, numDecimalMax, roundUp, science)
    },
    async getIsDeadlineReached(contract, account) {
      try {
        await metaMaskTools.getUserPunishTime(contract, account).then(async (data) => {
          console.log('getUserPunishTime: ' + data)
          this.isDeadlineReached = (parseInt(data) === 0);
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getTVL(account, index) {
      try {
        await metaMaskTools.getTVL(account).then(async (data) => {
          this.poolInfoArr[index].TVL = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getAPR(account, index) {
      try {
        await metaMaskTools.getAPR(account).then(async (data) => {
          this.poolInfoArr[index].APR = parseFloat(data) * 100
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getFeeRatio(account, index) {
      try {
        await metaMaskTools.getFeeRatio(account).then(async (data) => {
          this.poolInfoArr[index].feeRatio = parseFloat(data)
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getIsUserStake(account, index) {
      try {
        await metaMaskTools.getIsUserStake(account, this.accounts).then(async (data) => {
          this.poolInfoArr[index].isUserStake = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getPoolLimitInfo(account, index) {
      try {
        await metaMaskTools.getPoolLimitInfo(account).then(async (data) => {
          this.poolInfoArr[index].isPledgeRestriction = data.isPledgeRestriction
          this.poolInfoArr[index].isSetPoolQuota = data.isSetPoolQuota
          this.poolInfoArr[index].poolQuotaLimit = data.isSetPoolQuota ? parseFloat(data.poolQuotaLimit) : 0
          this.poolInfoArr[index].isSetUserQuota = data.isSetUserQuota
          this.poolInfoArr[index].userQuotaLimit = data.isSetUserQuota ? parseFloat(data.userQuotaLimit) : 0
          this.poolInfoArr[index].totalPower = parseFloat(data.totalPower)
          if (data.isPledgeRestriction && this.accounts && this.accounts !== 'null') {
            this.getIsUserStake(account, index);
          }
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isMortgage(contract, account, index) {
      try {
        await metaMaskTools.isMortgage(contract, account).then(async (data) => {
          this.poolInfoArr[index].isMortgage = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isStart(contract, index) {
      try {
        await metaMaskTools.isStart(contract).then(async (data) => {
          this.poolInfoArr[index].isStart = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async earned(contract, account, index) {
      try {
        await metaMaskTools.earned(contract, account).then(async (data) => {
          this.poolInfoArr[index].earned = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async power(contract, account, index) {
      try {
        await metaMaskTools.power(contract, account).then(async (data) => {
          this.poolInfoArr[index].staked = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async coinAllowanceCheers(lpToken, contract, account, index) {
      try {
        await metaMaskTools.coinAllowanceCheers(lpToken, contract, account).then(async (data) => {
          this.poolInfoArr[index].approve = parseFloat(data)
        });
      } catch (e) {
        console.log(e)
      }
    },
    async miningRemainingTime(contract, index) {
      try {
        await metaMaskTools.miningRemainingTime(contract).then(async (data) => {
          this.poolInfoArr[index].end = parseFloat(data)
        });
      } catch (e) {
        console.log(e)
      }
    },
    initMining() {
      for (let i = 0; i < this.poolInfoArr.length; i++) {
        this.getTVL(this.poolInfoArr[i].poolAddress, i);
        this.getAPR(this.poolInfoArr[i].poolAddress, i);
        this.getFeeRatio(this.poolInfoArr[i].poolAddress, i);
        this.isStart(this.poolInfoArr[i].poolAddress, i);
        this.miningRemainingTime(this.poolInfoArr[i].poolAddress, i);
        if (this.accounts && this.accounts !== 'null') {
          this.isMortgage(this.poolInfoArr[i].poolAddress, this.accounts, i);
          this.power(this.poolInfoArr[i].poolAddress, this.accounts, i);
          this.coinAllowanceCheers(this.poolInfoArr[i].lpToken, this.poolInfoArr[i].poolAddress, this.accounts, i);
        }
        if (this.poolInfoArr[i].isSetLimit) {
          this.getPoolLimitInfo(this.poolInfoArr[i].poolAddress, i);
        }
      }
    },
    initMiningTime() {
      for (let i = 0; i < this.poolInfoArr.length; i++) {
        if (this.poolInfoArr[i].end > 0) {
          this.poolInfoArr[i].endText = toolUtils.conversionTimestamp(this.poolInfoArr[i].end)
          this.poolInfoArr[i].end -= 1;
        }
        if (this.accounts && this.accounts !== 'null') {
          this.earned(this.poolInfoArr[i].poolAddress, this.accounts, i);
        }
        if (this.poolInfoArr[i].isSetLimit) {
          this.getPoolLimitInfo(this.poolInfoArr[i].poolAddress, i);
        }
      }
      setTimeout(() => {
        this.initMiningTime()
      }, 100000)
    },
    async coinApproveCheersMortgage(lpToken, contract, status) {
      if (status) {
        try {
          await metaMaskTools.coinApproveCheersMortgage(lpToken, contract, this.accounts).then(async (data) => {
            this.initMining()
          });
        } catch (e) {
          console.log(e)
        }
      } else {
        this.$message('The activity has not started yet!');
      }
    },
    async feeOperDialog(val, text, earn, daysNum, feeNum) {
      this.feeData = parseFloat(val)
      this.feeDialogShow = true
      this.stakeText = text
      this.daysNum = daysNum
      this.feeNum = feeNum

      if (earn === 'CHEERS') {
        if (this.$store.state.systemInfo.cheersPrice === 'TBA') {
          try {
            await metaMaskTools.getCheersPrice(this.$store.state.priceAddress).then(async (data) => {
              this.feePrice = data
            });
          } catch (e) {
            console.log(e)
          }
        } else {
          this.feePrice = this.$store.state.systemInfo.cheersPrice
        }
      } else if (earn === 'METAX') {
        try {
          await metaMaskTools.getMetaxLpPrice().then(async (data) => {
            this.feePrice = data
          });
        } catch (e) {
          console.log(e)
        }
      } else if (earn === 'TFI') {
        try {
          await metaMaskTools.getLpPrice().then(async (data) => {
            this.feePrice = data
          });
        } catch (e) {
          console.log(e)
        }
      } else if (earn === 'BABY') {
        try {
          await metaMaskTools.getBabyLpPrice().then(async (data) => {
            this.feePrice = data
          });
        } catch (e) {
          console.log(e)
        }
      } else if (earn === 'MILK') {
        try {
          await metaMaskTools.getMilkLpPrice().then(async (data) => {
            this.feePrice = data
          });
        } catch (e) {
          console.log(e)
        }
      } else if (earn === 'POLC') {
        try {
          await metaMaskTools.getPolcPrice().then(async (data) => {
            this.feePrice = data
          });
        } catch (e) {
          console.log(e)
        }
      } else if (earn === 'MONI') {
        try {
          await metaMaskTools.getMoniPrice().then(async (data) => {
            this.feePrice = data
          });
        } catch (e) {
          console.log(e)
        }
      } else if (earn === 'AOG') {
        try {
          await metaMaskTools.getAogPrice().then(async (data) => {
            this.feePrice = data
          });
        } catch (e) {
          console.log(e)
        }
      }
    },
    operDialog(index, title, val, stakeText, token) {
      this.poolIndex = index
      this.stakeDialogShow = true
      this.dialogTitle = title
      this.contract = val
      this.stakeText = stakeText
      this.lpTokenText = token

      this.getMax(index)

      if (title === 'unstake') {
        this.getIsDeadlineReached(val, this.accounts)
      }
    },
    dialogClose() {
      this.feeDialogShow = false
      this.stakeDialogShow = false
      this.amount = 0
      this.maxAmount = 0
      this.maxStake = 0
    },
    async getMax(index) {
      if (this.dialogTitle === 'unstake') {
        try {
          await metaMaskTools.power(this.contract, this.accounts).then(async (data) => {
            this.maxAmount = data
          });
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          await metaMaskTools.getLpBalanceOf(this.lpTokenText, this.accounts).then(async (data) => {
            this.maxAmount = data
            setTimeout(() => {
              if (this.poolInfoArr[index].isSetLimit) {
                if (this.poolInfoArr[index].poolQuotaLimit > 0) {
                  const poolHardCap = this.poolInfoArr[index].poolQuotaLimit - this.poolInfoArr[index].totalPower
                  this.maxStake = (this.maxAmount < poolHardCap) ? this.maxAmount : poolHardCap
                }
                if (this.maxStake === 0 && !this.poolInfoArr[index].poolQuotaLimit > 0) {
                  this.maxStake = this.maxAmount
                }
                if (this.poolInfoArr[index].userQuotaLimit > 0) {
                  const userHardCap = this.poolInfoArr[index].userQuotaLimit - this.poolInfoArr[index].staked
                  this.maxStake = (this.maxStake < userHardCap) ? this.maxStake : userHardCap
                }
              }
            }, 500)
          });
        } catch (e) {
          console.log(e)
        }
      }
    },
    setMax() {
      if (
          (this.maxStake > 0 || this.poolInfoArr[this.poolIndex].poolQuotaLimit > 0 || this.poolInfoArr[this.poolIndex].userQuotaLimit > 0)
          && this.dialogTitle === 'stake'
          && this.poolInfoArr[this.poolIndex].isSetLimit
      ) {
        this.amount = this.maxStake
      } else {
        this.amount = this.maxAmount
      }
    },
    async stake(contract) {
      try {
        if (!this.amount) {
          this.$message('Please enter the amount!');
          return
        }
        if (this.poolInfoArr[this.poolIndex].isPledgeRestriction) {
          if (this.poolInfoArr[this.poolIndex].isUserStake) {
            this.$message('You can only pledge once!');
            return
          }
        }
        if ((this.poolInfoArr[this.poolIndex].poolQuotaLimit > 0 || this.poolInfoArr[this.poolIndex].userQuotaLimit > 0)
            && this.poolInfoArr[this.poolIndex].isSetLimit
        ) {
          if (this.amount > this.maxStake) {
            this.$message('Maximum mortgage limit cannot be exceeded!');
            return
          }
        }
        if (parseFloat(this.amount) <= 0) {
          this.$message('Amount cannot be less than or equal to 0!');
          return
        }
        if (parseFloat(this.amount) > parseFloat(this.maxAmount)) {
          this.$message('Insufficient wallet balance!');
          return
        }
        await metaMaskTools.stake(contract, this.accounts, this.amount).then(async (data) => {
          this.getMax(this.poolIndex)
          this.initMining()
          this.amount = null
          this.dialogClose()
        }).catch(() => {
          this.dialogClose();
        });
      } catch (e) {
        console.log(e)
      }
    },
    async withdraw(contract) {
      try {
        if (!this.amount) {
          this.$message('Please enter the amount!');
          return
        }
        if (parseFloat(this.amount) <= 0) {
          this.$message('Amount cannot be less than or equal to 0!');
          return
        }
        if (parseFloat(this.amount) > parseFloat(this.maxAmount)) {
          this.$message('Insufficient withdrawal balance!');
          return
        }
        await metaMaskTools.withdraw(contract, this.accounts, this.amount).then(async (data) => {
          this.getMax(this.poolIndex)
          this.initMining()
          this.amount = null
          this.dialogClose()
        }).catch(() => {
          this.dialogClose();
        });
      } catch (e) {
        console.log(e)
      }
    },
    async harvest(contract, status) {
      if (status) {
        if (localStorage.getItem('accounts')) {
          this.accounts = localStorage.getItem('accounts')
        }
        if (!(this.accounts && this.accounts !== 'null')) {
          this.$message('Please connect your wallet first!');
          return
        }
        try {
          await metaMaskTools.receiveRewards(contract, this.accounts).then(async (data) => {
            this.initMining()
          });
        } catch (e) {
          console.log(e)
        }
      } else {
        this.$message('The activity has not started yet!');
      }
    }
  }
}
</script>

<style lang="less" scoped>
.m-top-banner-text {
  position: absolute;
  top: 1.35rem;
  left: 0.3rem;
  height: 0.43rem;
}

.m-page-title {
  font-weight: 700;
  font-size: 0.3rem;
  line-height: 0.5rem;
  color: #05F1A9;
  text-align: center;
}

.m-page-desc {
  font-size: 0.14rem;
  line-height: 0.21rem;
  color: #FFFFFF;
  max-width: 2.73rem;
  text-align: center;
  margin: 0 auto;
}

.m-switch-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.16rem;
  line-height: 110%;
  color: #ffffff;

  .bg {
    cursor: pointer;
    position: relative;
    width: 0.4rem;
    height: 0.16rem;
    background-color: #747474;
    border-radius: 0.16rem;
    margin-right: 0.11rem;
  }

  .btn {
    position: absolute;
    width: 0.16rem;
    height: 0.16rem;
    border-radius: 50%;
    background-color: #fb8f10;
    left: 0;
    transition: left 0.3s ease-out;
  }

  .on-bg {
    position: absolute;
    width: 0;
    height: 0.16rem;
    background-color: #6d3c1f;
    border-radius: 0.16rem;
    transition: width 0.3s ease-out;
  }
}

.m-filter-btn {
  width: 1.46rem;
  height: 0.43rem;
  border-radius: 0.06rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.16rem;
  color: #FFFFFF;
  margin-left: 0.1rem;
  cursor: pointer;
  background-color: #151041;
}

.m-filter-btn:first-child {
  margin-left: 0;
}

.m-filter-btn.is-selected {
  background: #FB8F10;
  border: none;
}

.pool-list-window {
  width: 4.14rem;
  height: 3.9rem;
  overflow: hidden;
}

.m-pool-list {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  transition: transform 0.2s ease-out;
  transform: translateX(0);

  .card {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 2.67rem;
    height: auto;
    min-height: 3.47rem;
    background-size: 100% 100%;
    background-image: url("/images/igo/bg_pool_card.png");
    text-align: center;
    padding: 0 0.41rem;
    margin-top: 0.22rem;

    .coin-logo {
      position: absolute;
      top: -0.22rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0.9rem;
      height: 0.9rem;
      background-size: cover;
      background-image: url("/images/igo/bg_pool_logo.png");
      display: flex;
      align-items: center;
      justify-content: center;

      .little-logo {
        position: absolute;
        top: 0.53rem;
        left: 0.6rem;
        width: 0.35rem;
        height: 0.35rem;
        background: #FFFFFF;
        border: 1px solid #23A4CB;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      img {
        border-radius: 50%;
        width: 0.3rem;
        height: 0.3rem;
      }

      .logo {
        width: 0.43rem;
        height: 0.43rem;
        background-size: cover;
        border-radius: 50%;
      }
    }

    .card-title {
      font-weight: 700;
      font-size: 0.18rem;
      line-height: 104.5%;
      letter-spacing: -0.015em;
      text-transform: uppercase;
      color: #FFFFFF;
      white-space: normal;
    }

    .tag {
      height: 0.17rem;
      background: #05F1A9;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.15rem;
      font-size: 0.1rem;
      color: #0C0530;
    }

    .panel-label {
      font-weight: 700;
      font-size: 0.12rem;
      line-height: 141.25%;
      color: #1AFFFF;
    }

    .panel-value {
      font-size: 0.12rem;
      line-height: 141.25%;
      color: #FFFFFF;
      display: flex;
      align-items: center;

      img {
        width: 0.08rem;
        height: 0.08rem;
        cursor: pointer;
        margin-left: 0.04rem;
      }
    }

    .x-line {
      border: 0.395556px solid #05F1A9;
    }

    .action-row {
      display: flex;
      justify-content: space-between;
      text-align: left;
      position: relative;

      .warn-text {
        position: absolute;
        font-size: 0.1rem;
        color: #FFFFFF;
        top: -0.24rem;
        right: 0;
        text-align: right;

        img {
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
        }

        img:hover + .detail-content {
          display: block;
        }

        .detail-content {
          position: absolute;
          left: 0;
          top: 100%;
          display: none;
          width: 1.5rem;
          height: 1.5rem;
          background: #0C0530;
          border-radius: 10px;
          font-weight: 300;
          font-size: 0.12rem;
          line-height: 130.25%;
          color: #FFFFFF;
          z-index: 10;
          text-align: left;
          padding: 0.1rem;

          ul li {
            padding-left: 0.1rem;
            white-space: normal;
          }

          ul li::before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #1AFFFF; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
        }
      }

      .action-label {
        font-size: 0.12rem;
        line-height: 141.25%;
        color: #FFFFFF;
        white-space: nowrap;
      }

      .action-value {
        font-weight: 700;
        font-size: 0.12rem;
        line-height: 141.25%;
        color: #FB8F10;
      }

      .act-btn {
        width: 0.92rem;
        height: 0.24rem;
        background-image: url("/images/connect_btn.png");
        background-size: 100% 100%;
        cursor: not-allowed;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 0.1rem;
        color: #FFFFFF;
      }

      .act-btn.enabled {
        cursor: pointer;
      }

      .act-btn.little-btn {
        width: 0.4rem;
        height: 0.24rem;
        margin-right: 0.08rem;
      }

      .little-btn:last-child {
        margin-right: 0;
      }
    }

    .bottom-part {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      font-size: 0.1rem;
      line-height: 104.5%;
      color: #ffffff;

      img {
        cursor: pointer;
        width: 0.12rem;
        height: 0.12rem;
      }
    }

    .detail-area {
      display: flex;
      flex-direction: column;
      position: relative;

      .detail-row {
        display: flex;
        align-items: center;
        text-align: left;

        .label {
          font-size: 0.1rem;
          line-height: 120.25%;
          color: #FFFFFF;
          width: 40%;
          white-space: nowrap;
        }

        .value {
          font-weight: 700;
          font-size: 0.12rem;
          line-height: 120.25%;
          color: #FFFFFF;
          white-space: nowrap;
          display: flex;
          align-items: center;

          img {
            width: 0.16rem;
            height: 0.16rem;
            margin-left: 0.04rem;
          }
        }
      }

      .up-btn {
        cursor: pointer;
        position: absolute;
        top: 0.11rem;
        right: 0;
        width: 0.12rem;
        height: 0.12rem;
      }

      .link {
        cursor: pointer;
        font-size: 0.12rem;
        line-height: 104.5%;
        text-decoration-line: underline;
        color: #ffffff;
        text-align: left;
        display: flex;

        img {
          height: 0.08rem;
          margin-left: 0.08rem;
        }
      }
    }
  }
}

.scroll-panel {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 0.12rem;
  }

  .prev-btn {
    width: 0.39rem;
    height: 0.39rem;
    border: 0.68421px solid #9b9b9b;
    margin-right: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .next-btn {
    width: 0.39rem;
    height: 0.39rem;
    background: #fb8f10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 1600px) {
  .action-row .action-label {
    font-size: 14px !important;
  }
}

.bg-text-1 {
  position: absolute;
  width: 133px;
  height: 79px;
  left: 20px;
  top: 200px;
  background-image: url("/images/farms/bg_text_1.png");
  background-size: cover;
}

.bg-text-2 {
  position: absolute;
  width: 137px;
  height: 79px;
  left: 526px;
  top: 591px;
  background-image: url("/images/farms/bg_text_2.png");
  background-size: cover;
}

.banner-bg {
  background-image: url("/images/farms/bg_top_banner.png");
  left: 0;
  width: 100vw;
  height: calc(100vw * (780 / 1920));
  background-size: cover;
}

.page-title {
  font-weight: 700;
  font-size: 80px;
  line-height: 57px;
  color: #05F1A9;
}

.page-desc {
  font-size: 18px;
  line-height: 154.5%;
  color: #FFFFFF;
  max-width: 570px;
}

.filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 86px;
  border-radius: 20px;
  padding: 0 38px 0 35px;

  .switch-panel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 110%;
    color: #ffffff;

    .bg {
      cursor: pointer;
      position: relative;
      width: 40px;
      height: 20px;
      background-color: #747474;
      border-radius: 16px;
      margin-right: 16px;
    }

    .btn {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fb8f10;
      left: 0;
      transition: left 0.3s ease-out;
    }

    .on-bg {
      position: absolute;
      width: 0;
      height: 20px;
      background-color: #6d3c1f;
      border-radius: 16px;
      transition: width 0.3s ease-out;
    }
  }

  .filter-btn {
    width: 20vw;
    height: 67px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 26px;
    color: #FFFFFF;
    margin-left: 30px;
    cursor: pointer;
    background-color: #151041;
  }

  .filter-btn.is-selected {
    background: #FB8F10;
    border: none;
  }
}

.pool-list {
  position: relative;
  padding-bottom: 40px;
  display: flex;
  align-items: flex-start;
  transition: height 0.3s ease-out;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;

  .card:nth-child(3n) {
    margin-right: 0;
  }

  .card {
    position: relative;
    width: 30%;
    height: auto;
    min-height: 531px;
    background-size: 100% 100%;
    background-image: url("/images/igo/bg_pool_card.png");
    text-align: center;
    padding: 0 3.75%;
    margin-right: 5%;

    .coin-logo {
      position: absolute;
      top: -19px;
      left: 50%;
      transform: translateX(-50%);
      width: 134px;
      height: 134px;
      background-size: cover;
      background-image: url("/images/igo/bg_pool_logo.png");
      display: flex;
      align-items: center;
      justify-content: center;

      .little-logo {
        position: absolute;
        top: 80px;
        left: 90px;
        width: 53px;
        height: 53px;
        background: #FFFFFF;
        border: 1px solid #23A4CB;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
      }

      .logo {
        width: 74px;
        height: 74px;
        background-size: cover;
        border-radius: 50%;
      }
    }

    .card-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 104.5%;
      letter-spacing: -0.015em;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .tag {
      height: 25px;
      background: #05F1A9;
      border-radius: 30px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      font-size: 14px;
      color: #0C0530;

    }

    .panel-label {
      font-weight: 700;
      font-size: 16px;
      line-height: 141.25%;
      color: #1AFFFF;
    }

    .panel-value {
      font-size: 16px;
      line-height: 141.25%;
      color: #FFFFFF;
      display: flex;
      align-items: center;

      img {
        width: 11px;
        height: 11px;
        cursor: pointer;
        margin-left: 6px;
      }
    }

    .x-line {
      border: 0.595556px solid #05F1A9;
    }

    .action-row {
      display: flex;
      justify-content: space-between;
      text-align: left;
      position: relative;

      .warn-text {
        position: absolute;
        font-size: 14px;
        color: #FFFFFF;
        top: -30px;
        right: 0;
        text-align: right;

        img {
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
        }

        img:hover + .detail-content {
          display: block;
        }

        .detail-content {
          position: absolute;
          left: 100%;
          bottom: 0;
          display: none;
          width: 186px;
          height: 105px;
          background: #0C0530;
          border-radius: 10px;
          font-weight: 300;
          font-size: 12px;
          line-height: 130.25%;
          color: #FFFFFF;
          z-index: 10;
          text-align: left;
          padding: 12px 10px;

          ul li {
            padding-left: 1rem;
          }

          ul li::before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #1AFFFF; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
        }
      }

      .action-label {
        font-size: 16px;
        line-height: 141.25%;
        color: #FFFFFF;
        white-space: nowrap;
      }

      .action-value {
        font-weight: 700;
        font-size: 16px;
        line-height: 141.25%;
        color: #FB8F10;
      }

      .act-btn {
        width: 139px;
        height: 47px;
        background-image: url("/images/connect_btn.png");
        background-size: 100% 100%;
        cursor: not-allowed;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 13.2245px;
        color: #FFFFFF;
      }

      .act-btn.enabled {
        cursor: pointer;
      }

      .act-btn.little-btn {
        width: 65px;
        height: 47px;
        margin-right: 16px;
      }

      .little-btn:last-child {
        margin-right: 0;
      }
    }

    .bottom-part {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      font-size: 16px;
      line-height: 104.5%;
      color: #ffffff;

      img {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }

    .detail-area {
      display: flex;
      flex-direction: column;
      position: relative;

      .detail-row {
        display: flex;
        align-items: center;
        text-align: left;

        .label {
          font-size: 12px;
          line-height: 120.25%;
          color: #FFFFFF;
          width: 40%;
          white-space: nowrap;
        }

        .value {
          font-weight: 700;
          font-size: 14px;
          line-height: 120.25%;
          color: #FFFFFF;
          white-space: nowrap;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
            margin-left: 4px;
          }
        }
      }

      .up-btn {
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 0;
        width: 16px;
        height: 16px;
      }

      .link {
        cursor: pointer;
        font-size: 12px;
        line-height: 104.5%;
        text-decoration-line: underline;
        color: #ffffff;
        text-align: left;
        display: flex;

        img {
          height: 8px;
          margin-left: 9px;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#100632, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.stake_dialog {
  position: relative;
  width: 800px;
  height: auto;
  min-height: 464px;
  background-size: 100% 100%;
  background-image: url("/images/bg_popup.png");
  padding: 118px 85px 102px;
  font-size: 18px;
  color: white;

  .close-btn {
    position: absolute;
    width: 34px;
    height: 34px;
    right: 59px;
    top: 76px;
    z-index: 1;
    cursor: pointer;
    background-size: cover;
    background-image: url("/images/icon_close_btn.png");
  }

  .x-line {
    height: 0px;
    border: 0.595556px solid #05F1A9;
  }

  .stake_top {
    font-weight: 700;
    font-size: 56px;
    line-height: 95.5%;
    letter-spacing: -0.055em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .panel {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;

    .amount-input {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #1b3696;
      width: 200px;
      height: 44px;
      text-align: center;
      border: none;
      outline: none;
      background-color: transparent;
      background-image: url("/images/farms/bg_input.png");
      background-size: 100% 100%;
      padding: 0;
    }

    .max-btn {
      cursor: pointer;
      width: 169px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      border: solid 1px #ffec00;
      border-radius: 16px;
      background-color: #ff8f00;
      box-shadow: inset 0 0.5px 0 1px black, 0 0.5px 0 1px #ff8f00,
      0 0.5px 15px 1px #ff8f00;
    }
  }

  .confirm {
    width: 139px;
    height: 47px;
    background-image: url("/images/connect_btn.png");
    background-size: 100% 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13.2245px;
    color: #FFFFFF;
  }

  .confirm:not(:last-child) {
    margin-right: 28px;
  }

  .tips {
    margin-top: 20px;
    font-size: 16px;
    color: white;

    a {
      font-weight: 600;
      color: #ff8f00;
      text-decoration-line: underline;
    }
  }
}

.m-stake_dialog {
  position: relative;
  width: 3.16rem;
  height: auto;
  background-size: 100% 100%;
  background-image: url("/images/bg_popup.png");
  padding: 0.73rem 0.38rem 0.58rem;
  color: white;
  font-size: 0.14rem;
  line-height: 0.18rem;
  font-weight: 500;
  text-align: center;

  .close-btn {
    position: absolute;
    width: 0.21rem;
    height: 0.21rem;
    right: 0.32rem;
    top: 0.47rem;
    z-index: 1;
    cursor: pointer;
    background-size: cover;
    background-image: url("/images/icon_close_btn.png");
  }

  .x-line {
    height: 0;
    border: 0.345556px solid #05F1A9;
  }

  .stake_top {
    font-weight: 700;
    font-size: 0.28rem;
    line-height: 95.5%;
    letter-spacing: -0.055em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .amount-input {
    font-weight: 500;
    font-size: 0.16rem;
    line-height: 0.2rem;
    text-transform: uppercase;
    color: #1b3696;
    width: 1.92rem;
    height: 0.32rem;
    text-align: center;
    border: none;
    outline: none;
    background-color: transparent;
    background-image: url("/images/farms/bg_input.png");
    background-size: 100% 100%;
    padding: 0;
  }

  .max-btn {
    cursor: pointer;
    width: 1.13rem;
    height: 0.22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 0.14rem;
    color: #ffffff;
    border: solid 1px #ffec00;
    border-radius: 16px;
    background-color: #ff8f00;
    box-shadow: inset 0 0.5px 0 1px black, 0 0.5px 0 1px #ff8f00,
    0 0.5px 15px 1px #ff8f00;
    margin: 0 auto;
  }

  .confirm {
    width: 0.99rem;
    height: 0.32rem;
    background-image: url("/images/connect_btn.png");
    background-size: 100% 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.14rem;
    color: #FFFFFF;
  }

  .confirm:not(:last-child) {
    margin-right: 0.1rem;
  }

  .tips {
    margin-top: 0.12rem;
    font-size: 0.12rem;
    color: white;

    a {
      font-weight: 600;
      color: #ff8f00;
      text-decoration-line: underline;
    }
  }
}
</style>
