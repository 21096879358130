










































import toolUtils from "../../tools/toolUtils";

export default {
  name: "PopupROI",
  props: ['feeData', 'stakeText', 'feePrice', 'daysNum', 'feeNum'],
  emits: ['close'],
  data() {
    return {
    };
  },
  methods: {
    setDecimal(numVal, numDecimalMax, roundUp, science) {
      return toolUtils.setDecimal(numVal, numDecimalMax, roundUp, science)
    },
    close() {
      this.$emit('close')
    }
  }
};
