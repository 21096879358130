export const farmsProjectList = [
    // 正式
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: false,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 10,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'Unlimited',
        poolType: 'token',
        poolName: 'Whitelist for IGO',
        poolAddress: '0x4CFe94B7A7609E00389B875a7F7B13d89954507A',
        stake: 'CHEERS',
        lpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        lpImg: require('@/assets/images/pc/cheers_lp.png'),
        earn: 'CHEERS',
        earnLpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://cheersland.org/',
        viewContract: '0x4CFe94B7A7609E00389B875a7F7B13d89954507A'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: true,
        isSetUserQuota: true,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'Metaverse',
        poolType: 'token',
        poolName: 'TFI-POLC',
        poolAddress: '0xfaE64A6691dA9eD1cBd700c1E0d3D8625B865856',
        stake: 'TFI',
        lpToken: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'POLC',
        earnLpToken: '0x6ae9701b9c423f40d54556c9a443409d79ce170a',
        earnImg: require('@/assets/images/pc/polc_logo.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://www.polkacity.io/',
        viewContract: '0xfaE64A6691dA9eD1cBd700c1E0d3D8625B865856'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: true,
        isSetUserQuota: true,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'Metaverse',
        poolType: 'token',
        poolName: 'TFI-MONI',
        poolAddress: '0x825CDDD8B76b53D665952F19c29C902c663501Dc',
        stake: 'TFI',
        lpToken: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'MONI',
        earnLpToken: '0x9573c88aE3e37508f87649f87c4dd5373C9F31e0',
        earnImg: require('@/assets/images/pc/moni_logo.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://monstainfinite.com/',
        viewContract: '0x825CDDD8B76b53D665952F19c29C902c663501Dc'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: true,
        isSetUserQuota: true,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'Metaverse',
        poolType: 'token',
        poolName: 'TFI-AOG',
        poolAddress: '0x1Dd1D8972C3F803DC2333cF6B613A827651d6263',
        stake: 'TFI',
        lpToken: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'AOG',
        earnLpToken: '0x40c8225329bd3e28a043b029e0d07a5344d2c27c',
        earnImg: require('@/assets/images/pc/aog_logo.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://ageofgods.net/',
        viewContract: '0x1Dd1D8972C3F803DC2333cF6B613A827651d6263'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: true,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 10,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'TrustFi',
        poolType: 'token',
        poolName: 'Golden Pool',
        poolAddress: '0xf3aAB863b69098FD191c1a20fCB9ac8b1Af42be7',
        stake: 'TFI',
        lpToken: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'TFI',
        earnLpToken: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
        earnImg: require('@/assets/images/pc/tfi.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://trustfi.org/',
        viewContract: '0xf3aAB863b69098FD191c1a20fCB9ac8b1Af42be7'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: false,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'Unlimited',
        poolType: 'token',
        poolName: 'CHEERS-MILK',
        poolAddress: '0x9344D3bf77e3ecd6CB8666A4E78FD26Fe5a1946f',
        stake: 'CHEERS',
        lpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        lpImg: require('@/assets/images/pc/cheers_lp.png'),
        earn: 'MILK',
        earnLpToken: '0xBf37f781473f3b50E82C668352984865eac9853f',
        earnImg: require('@/assets/images/pc/MILK-logo.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://thecryptoyou.io/home',
        viewContract: '0x9344D3bf77e3ecd6CB8666A4E78FD26Fe5a1946f'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: true,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 10,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'CheersLand',
        poolType: 'token',
        poolName: 'CHEERS Loyalty Governance',
        poolAddress: '0xfBEf1bD32e515D87C8b755ce59edfE64B8B4DDb5',
        stake: 'CHEERS',
        lpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        lpImg: require('@/assets/images/pc/cheers_lp.png'),
        earn: 'CHEERS',
        earnLpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://cheersland.org/',
        viewContract: '0xfBEf1bD32e515D87C8b755ce59edfE64B8B4DDb5'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: false,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'Unlimited',
        poolType: 'token',
        poolName: 'TFI-MILK',
        poolAddress: '0x9eaf92e7F1d291fC53289322CDBcF8751A27CB50',
        stake: 'TFI',
        lpToken: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'MILK',
        earnLpToken: '0xbf37f781473f3b50e82c668352984865eac9853f',
        earnImg: require('@/assets/images/pc/MILK-logo.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://thecryptoyou.io/home',
        viewContract: '0x9eaf92e7F1d291fC53289322CDBcF8751A27CB50'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: false,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 0,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'BabySwap',
        poolType: 'lp',
        poolName: 'BABY LP-TFI',
        poolAddress: '0x88FE77Bd9139387533E5447Fe99fD4Df7a6EaE9e',
        stake: 'MILK-USDT',
        lpToken: '0xDB6c248902AD2722A5E01E9689824c28bd0A5f75',
        lpImg: require('@/assets/images/pc/milk_lp.png'),
        earn: 'TFI',
        earnLpToken: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
        earnImg: require('@/assets/images/pc/tfi.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://trustfi.org/',
        viewContract: '0x88FE77Bd9139387533E5447Fe99fD4Df7a6EaE9e'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: true,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'TrustFi',
        poolType: 'token',
        poolName: 'TFI-BABY',
        poolAddress: '0x2EAAF0fBECf731A72E589DE08ef6F73766EF286e',
        stake: 'TFI',
        lpToken: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'BABY',
        earnLpToken: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
        earnImg: require('@/assets/images/pc/Baby_Logo.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://home.babyswap.finance/',
        viewContract: '0x2EAAF0fBECf731A72E589DE08ef6F73766EF286e'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: true,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'Unlimited',
        poolType: 'token',
        poolName: 'CHEERS-CHEERS#2',
        poolAddress: '0xD4E416f7AAfE0eE456f6936cAd9f3bf9012E694f',
        stake: 'CHEERS',
        lpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        lpImg: require('@/assets/images/pc/cheers_lp.png'),
        earn: 'CHEERS',
        earnLpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://cheersland.org/',
        viewContract: '0xD4E416f7AAfE0eE456f6936cAd9f3bf9012E694f'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: true,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: true,
        feeRatio: 0,
        punishTime: 10,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'TrustFi',
        poolType: 'token',
        poolName: 'TFI Loyalty Governance',
        poolAddress: '0x5241CF262C73f321C12683196B9a83C6f444e2ad',
        stake: 'TFI',
        lpToken: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'TFI',
        earnLpToken: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        earnImg: require('@/assets/images/pc/tfi.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://trustfi.org/',
        viewContract: '0x5241CF262C73f321C12683196B9a83C6f444e2ad'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: '',
        poolType: 'lp',
        poolName: 'LP-CHEERS',
        poolAddress: '0x0a62D95739958001C7eeA5fDDbcF3ca7fb4494ba',
        stake: 'CHEERS-BUSD',
        lpToken: '0xee244c7e1e92e803af29137df6fe623a8311bb7b',
        lpImg: require('@/assets/images/pc/cheers_lp_tb.png'),
        earn: 'CHEERS',
        earnLpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://cheersland.org/',
        viewContract: '0x0a62D95739958001C7eeA5fDDbcF3ca7fb4494ba'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: true,
        isPledgeRestriction: false,
        isSetPoolQuota: true,
        isSetUserQuota: true,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'FCFS',
        poolType: 'token',
        poolName: 'CHEERS-CHEERS',
        poolAddress: '0xD6A10407665f0C8F9b63e330d36F2845FCE37B18',
        stake: 'CHEERS',
        lpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        lpImg: require('@/assets/images/pc/cheers_lp.png'),
        earn: 'CHEERS',
        earnLpToken: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://cheersland.org/',
        viewContract: '0xD6A10407665f0C8F9b63e330d36F2845FCE37B18'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: false,
        isSetLimit: false,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'FULL',
        poolType: 'lp',
        poolName: 'LP-TFI',
        poolAddress: '0xa0668C22d2808e7d83c8a3B16A45052aEb2F0768',
        stake: 'TFI-BNB',
        lpToken: '0xb1c508f942c1d1b1a44384bcc92885e43887d0fa',
        lpImg: require('@/assets/images/pc/tfi_bnb_lp.png'),
        earn: 'TFI',
        earnLpToken: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        earnImg: require('@/assets/images/pc/tfi.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://trustfi.org/',
        viewContract: '0xa0668C22d2808e7d83c8a3B16A45052aEb2F0768'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: true,
        isSetLimit: false,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: '',
        poolType: 'token',
        poolName: 'METAX-METAX',
        poolAddress: '0xd11EC60CeC0FcB1b74D9Fb15cDa0aF65e7882e35',
        stake: 'METAX',
        lpToken: '0x03f8fdc10d5bcf7508375585b04e93d656d36954',
        lpImg: require('@/assets/images/pc/metax.png'),
        earn: 'METAX',
        earnLpToken: '0x03f8fdc10d5bcf7508375585b04e93d656d36954',
        earnImg: require('@/assets/images/pc/metax.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://metax.game',
        viewContract: '0xd11EC60CeC0FcB1b74D9Fb15cDa0aF65e7882e35'
    },
    {
        hide: false,
        isMortgage: false,
        isStart: false,
        isStake: false,
        isSetLimit: false,
        isPledgeRestriction: false,
        isSetPoolQuota: false,
        isSetUserQuota: false,
        isUserStake: false,
        feeRatio: 0,
        punishTime: 7,
        poolQuotaLimit: 0,
        userQuotaLimit: 0,
        totalPower: 0,
        poolSign: 'FULL',
        poolType: 'token',
        poolName: 'TFI-TFI',
        poolAddress: '0xb923919fa6Df7675aa33069425aD42878BF39206',
        stake: 'TFI',
        lpToken: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        lpImg: require('@/assets/images/pc/tfi.png'),
        earn: 'TFI',
        earnLpToken: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        earnImg: require('@/assets/images/pc/tfi.png'),
        APR: 0,
        earned: 0,
        staked: 0,
        TVL: 0,
        approve: 0,
        end: 0,
        endText: '',
        viewProjectSite: 'https://trustfi.org/',
        viewContract: '0xb923919fa6Df7675aa33069425aD42878BF39206'
    }
]
